import React, {Fragment, FC, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import {Link, navigate} from 'gatsby';

import Section from '../../components/section';
import ToolTabs from '../../components/scan-tools/ToolTabs';
import Layout from '../../components/layout/layout';
import {DomainForm} from '../../components/form/domain-form';
import {Translate} from '../../components/translations';
import {PageHero} from '../../components/page-hero';
import ScanCountChecker from '../../components/scan-tools/scan-counter';
import ToolToken from '../../components/scan-tools/ToolToken';
import WebMetricsTool from '../../components/scan-tools/webMetricsTool';
import {Modal} from '../../components/modal';
import Button from '../../components/button';

import {TrackToolScan} from '../../helpers/trackingEvents';
import {ScanResultsPageProps} from '../../types';

import speedTool from '../../img/home-tools/page-speed.png';
import hexomaticCTA from '../../img/hexomatic-banner/hexomatic-cta.jpg';

const WebMetricsPage: FC<ScanResultsPageProps> = props => {
  const [scanLimitReached, setScanLimitReached] = useState(false);
  const url = props['*'];
  const hasDomain = url && url.length > 0;

  const isMobile = useMediaQuery({maxWidth: 767});

  return (
    <Fragment>
      <Layout>
        <PageHero
          title={'Page load analyzer tool - Test your website’s performance'}
          subtitle={'Enter a URL to test page load speed and analyze the results to identify performance bottlenecks.'}
        >
          <DomainForm value={url} onSubmit={u => navigate(`/page-speed-scanner/${encodeURIComponent(u)}`)} />
        </PageHero>
        <Section>
          {isMobile ? (
            <>
              <div className="col-md-9 tool-detail">
                <div className="row">
                  <div className="col-md-12">
                    {hasDomain && url ? (
                      <ScanCountChecker url={url}>
                        <ToolToken url={url}>
                          {sessionToken => {
                            TrackToolScan('WebMetricsTool', url);
                            return (
                              <div className="row">
                                <div className="col-md-12">
                                  <WebMetricsTool
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </ToolToken>
                      </ScanCountChecker>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-4" style={{marginBottom: '20px'}}>
                            <img src={speedTool} alt="" style={{width: '100%', maxWidth: '300px'}} />
                          </div>
                          <div className="col-md-8">
                            <p>
                              Slow loading pages increase bounce rate and can negatively impact your website rankings in
                              search engines.
                            </p>
                            <p>
                              The ideal website load time is from 2 to 3 seconds. However, about 47% of internet users
                              expect desktop sites to load in 2 seconds or less.
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <strong>
                                See the data below to have an idea about how slow website page loading speeds can affect
                                your conversions:
                              </strong>
                            </p>
                            <ul>
                              <li>Up to 3 second Load Time – 32% Bounce rate increase</li>
                              <li>Up to 5 second Load Time – 90% Bounce rate increase</li>
                              <li>Up to 6 second Load Time – 106% Bounce rate increase</li>
                              <li>Up to 10 second Load Time – 123% Bounce rate increase</li>
                            </ul>
                            <p>source: 2017 Google/SOASTA Research</p>
                            <p>
                              In order to truly take advantage of this information, you must understand where your
                              current site stands. Check your website with our free website speed test to get detailed
                              insights regarding your website load performance.
                            </p>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: '30px'}}>
                          <div className="col-md-6" style={{margin: 'auto'}}>
                            <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                          </div>
                          <div className="col-md-6">
                            <div className="flex-align-center">
                              <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                              <p>
                                Harness the internet as your own data source, build your own scraping bots and leverage
                                ready made automations to delegate time consuming tasks and scale your business.
                              </p>
                              <p>No coding or PhD in programming required.</p>
                              <div>
                                <Button
                                  hexomaticCTA
                                  className="monitor_btn"
                                  onClick={() =>
                                    (window.location.href =
                                      'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                  }
                                >
                                  Get started in minutes
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong>Get detailed performance insights including:</strong>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>First Contentful Paint</li>
                              <li>First Meaningful Paint</li>
                              <li>Speed Index</li>
                              <li>Time to Interactive</li>
                              <li>First CPU Idle</li>
                              <li>Max Potential First Input Delay</li>
                              <li>Estimated Input Latency</li>
                              <li>Eliminate render-blocking resources</li>
                              <li>Properly size images</li>
                              <li>Defer offscreen images</li>
                              <li>Remove unused CSS</li>
                              <li>Serve images in next-gen formats</li>
                              <li>Preconnect to required origins</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>Server response times are low (TTFB)</li>
                              <li>Avoid multiple page redirects</li>
                              <li>Avoids enormous network payloads</li>
                              <li>Serve static assets with an efficient cache policy</li>
                              <li>Avoids an excessive DOM size</li>
                              <li>Minimize Critical Requests Depth</li>
                              <li>User Timing marks and measures</li>
                              <li>Reduce JavaScript execution time</li>
                              <li>Minimize main-thread work</li>
                              <li>Keep request counts low and transfer sizes small</li>
                              <li>Network Round Trip Times</li>
                              <li>Server Backend Latencies</li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {scanLimitReached && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      <p>&nbsp;</p>
                      <Modal
                        isOpen={true}
                        modalToggle={() => {}}
                        title="LOGIN_TO_CONTINUE"
                        modalFooter={
                          <Link to="/#login" state={{goBack: true}}>
                            <button type="button" className="btn btn-secondary">
                              <Translate name="GO_TO_LOGIN_PAGE" />
                            </button>
                          </Link>
                        }
                      >
                        <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      </Modal>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-3">
                <ToolTabs active="web-metrics" url={url} />
              </div>
            </>
          ) : (
            <>
              <div className="col-md-3">
                <ToolTabs active="web-metrics" url={url} />
              </div>
              <div className="col-md-9 tool-detail">
                <div className="row">
                  <div className="col-md-12">
                    {hasDomain && url ? (
                      <ScanCountChecker url={url}>
                        <ToolToken url={url}>
                          {sessionToken => {
                            TrackToolScan('WebMetricsTool', url);
                            return (
                              <div className="row">
                                <div className="col-md-12">
                                  <WebMetricsTool
                                    url={url}
                                    sessionToken={sessionToken}
                                    handleScanLimitCheck={(limitReached: boolean) => setScanLimitReached(limitReached)}
                                  />
                                </div>
                              </div>
                            );
                          }}
                        </ToolToken>
                      </ScanCountChecker>
                    ) : (
                      <>
                        <div className="row">
                          <div className="col-md-4" style={{marginBottom: '20px'}}>
                            <img src={speedTool} alt="" style={{width: '100%', maxWidth: '300px'}} />
                          </div>
                          <div className="col-md-8">
                            <p>
                              Slow loading pages increase bounce rate and can negatively impact your website rankings in
                              search engines.
                            </p>
                            <p>
                              The ideal website load time is from 2 to 3 seconds. However, about 47% of internet users
                              expect desktop sites to load in 2 seconds or less.
                            </p>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <strong>
                                See the data below to have an idea about how slow website page loading speeds can affect
                                your conversions:
                              </strong>
                            </p>
                            <ul>
                              <li>Up to 3 second Load Time – 32% Bounce rate increase</li>
                              <li>Up to 5 second Load Time – 90% Bounce rate increase</li>
                              <li>Up to 6 second Load Time – 106% Bounce rate increase</li>
                              <li>Up to 10 second Load Time – 123% Bounce rate increase</li>
                            </ul>
                            <p>source: 2017 Google/SOASTA Research</p>
                            <p>
                              In order to truly take advantage of this information, you must understand where your
                              current site stands. Check your website with our free website speed test to get detailed
                              insights regarding your website load performance.
                            </p>
                          </div>
                        </div>
                        <div className="row" style={{marginTop: '30px'}}>
                          <div className="col-md-6" style={{margin: 'auto'}}>
                            <img src={hexomaticCTA} alt="Hexomatic" style={{width: '100%', maxWidth: '450px'}} />
                          </div>
                          <div className="col-md-6">
                            <div className="flex-align-center">
                              <h5 className="h5-title">Automate & scale time-consuming tasks like never before with Hexomatic</h5>
                              <p>
                                Harness the internet as your own data source, build your own scraping bots and leverage
                                ready made automations to delegate time consuming tasks and scale your business.
                              </p>
                              <p>No coding or PhD in programming required.</p>
                              <div>
                                <Button
                                  hexomaticCTA
                                  className="monitor_btn"
                                  onClick={() =>
                                    (window.location.href =
                                      'https://hexomatic.com/?utm_source=hexometer&utm_medium=tools-cta')
                                  }
                                >
                                  Get started in minutes
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              <strong>Get detailed performance insights including:</strong>
                            </p>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>First Contentful Paint</li>
                              <li>First Meaningful Paint</li>
                              <li>Speed Index</li>
                              <li>Time to Interactive</li>
                              <li>First CPU Idle</li>
                              <li>Max Potential First Input Delay</li>
                              <li>Estimated Input Latency</li>
                              <li>Eliminate render-blocking resources</li>
                              <li>Properly size images</li>
                              <li>Defer offscreen images</li>
                              <li>Remove unused CSS</li>
                              <li>Serve images in next-gen formats</li>
                              <li>Preconnect to required origins</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul>
                              <li>Server response times are low (TTFB)</li>
                              <li>Avoid multiple page redirects</li>
                              <li>Avoids enormous network payloads</li>
                              <li>Serve static assets with an efficient cache policy</li>
                              <li>Avoids an excessive DOM size</li>
                              <li>Minimize Critical Requests Depth</li>
                              <li>User Timing marks and measures</li>
                              <li>Reduce JavaScript execution time</li>
                              <li>Minimize main-thread work</li>
                              <li>Keep request counts low and transfer sizes small</li>
                              <li>Network Round Trip Times</li>
                              <li>Server Backend Latencies</li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {scanLimitReached && (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      <p>&nbsp;</p>
                      <Modal
                        isOpen={true}
                        modalToggle={() => {}}
                        title="LOGIN_TO_CONTINUE"
                        modalFooter={
                          <Link to="/#login" state={{goBack: true}}>
                            <button type="button" className="btn btn-secondary">
                              <Translate name="GO_TO_LOGIN_PAGE" />
                            </button>
                          </Link>
                        }
                      >
                        <Translate name="REGISTER_TO_GET_UNLIMITED_SCAN_ACCESS" />
                      </Modal>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </Section>
      </Layout>
    </Fragment>
  );
};

export default WebMetricsPage;
